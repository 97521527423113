/*============= 
            Custom Medie Query
===============*/



@media (max-width: 992px)
{
    .tracking_modal_lg{
        max-width: 100%;
        padding: 0 0.5rem;
    }
}
@media (max-width:767px){
    .pr_small {
        padding-right: 12px !important;
    }
}
@media (max-width:628px){
    .wp_tracking table tr td {
        white-space: nowrap
    }
    .wp_tracking .table-responsive > small {
        margin-top: 5px;
    }
}
@media (max-width:576px){
    .tracking_modal_lg{
        max-width: 100%;
        padding: 0 0rem;
    }
}
