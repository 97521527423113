/*=================
        Custom Classes CSS
===================*/
:root{
    --clr-1:#d2d7dd;
    --clr-2: #e3ebf6;
}
/* Height 100vh CSS */

*{
    margin: 0;
    padding: 0;
    list-style: none;
    box-sizing: border-box;
}
.h_100vh{
    height: 100vh;
}
.c_pointer{
    cursor: pointer;
}
.lift{
    width: 20%;
    float: right;
    margin-left: 75%;
    margin-top: 10px;
}

::placeholder {
    color: r#12263F!important;
    opacity: 1; /* Firefox */
  }

  /* .selectColor{
    background-color: red;
  } */
.modal.fade {
    padding-left: 0px !important;
}
.cancel-btn{
    float: right;
    margin-top: -3.7%;
}
.wid_col {
    width: 20%;
}
/* Input */

.form_input {
    width: 100px !important;
}
.f_out:focus{
    box-shadow: none !important;
    outline: none;
    border-color: var(--clr-1);
}


.font_14 .fe {
    font-size: 20px;
}

/* Slash */
.slash {
    width: 1px;
    height: 14px;
    background: #000;
    transform: rotate(10deg);
    margin: 0 2px;
}
/* Button Lg */
.btn_space{
    padding: 9px 12px;
}

/* link Cs */
.link_cs {
    color: #12263F;
}
.link_cs:hover {
    color: #12263F;
}

/* vertical align */
.align_virtical{
    vertical-align: middle;
}

/* Header Button CSS */
.header_cs_btn {
    font-size: 0.938;
    align-items: center;
    display: flex;
}
/* Button Focus CSS */
.focus_out:focus{
    box-shadow: none;
    outline: none;
}

/* Border Radius CSS */
.radious_4{
    border-radius: 0.25rem;
} 

/* table */
.forget{
    float: right;
    margin-right: -10px;
    margin: px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.cs_table tr th , .cs_table tr td{
    border-bottom:1px solid var(--clr-2) ;
    border-top: 0px;
    padding: 12px 8px;
    white-space:nowrap
}

.cs_table td{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100vw);
}

/* Breadcum */
.breadcrumb li a:hover{
    color: #2C7BE5;
}

/* Tab Section */

.tab_list_section > ul{
    border: 0px;
    border-bottom: 1px solid var(--clr-2);
}
.tab_list_section.tab_sub_list > ul{
    border: 0px;
    border-bottom: none;
}
.tab_list_section.tab_sub_list > ul > li.react-tabs__tab{
    font-size:15px;
}
.tab_list_section.tab_sub_list > ul > li.react-tabs__tab--selected{
    border:none;
}   
.tab_list_section > ul > li.react-tabs__tab{
    border: 0px;
    padding: 1rem 0px;
    color: #95aac9;
    font-size: 15px;
    font-weight: 400;
    margin-left: .75rem;
    margin-right: .75rem;
    white-space: nowrap;
}
.tab_list_section > ul > li.react-tabs__tab:first-child{
    margin-left: 0px;
}
.tab_list_section > ul > li.react-tabs__tab--selected{
    border: 0px;
    background: transparent;
    border-radius: 0px;
    color: #12263f;
    border-bottom: 1px solid #2c7be5;
}
.tab_list_section > ul > li.react-tabs__tab:focus {
    box-shadow: none;
    border-color: transparent;
    outline: none;
}

.first_list li {
    border-right: 1px solid var(--clr-1);
    padding: 0 1rem;
    font-size: 13px;
    cursor: pointer;
    white-space: nowrap;
}
.first_list li:first-child{
    padding-left: 0px;
}
.first_list li:last-child{
    border-right: 0px;
}

/* tab Table */
.tab_table table tr td{
    border: 0px;
    padding: 9px 8px;
}
.tab_table table tr td:first-child{
    font-weight: 500;
    width: 300px;
    padding-left: 0px;
}

/* Sereening Table */
.Screening_table table thead tr th, .Screening_table table tbody tr td{
    padding: 8px 12px;
    border: 0 !important;
    white-space: nowrap;
}
.Screening_table table thead tr th{
    padding: 8px 12px;
    border-bottom:1px solid  var(--clr-2) !important;
    
}
.Screening_table table tbody tr:first-child td{
    padding-bottom: 0px;
}
.Screening_table .wid_last .dropdown-item{
    font-size: 14px;
}
.Screening_table .wid_last .dropdown-menu{
    padding:0.188rem 0;
}
.save_cancel_button{
    position: absolute;
    bottom: 15px;
    right: 25px;
}
.save{
    width: 100px;
}
.create_options input[type="radio"], 
.create_options input[type="checkbox"]{
    width:18px;
    height:18px;
}
.create_options .form-check{
    display:flex;
    align-items:center;
}
.create_options .form-check-label{
    font-size:15px;
}

/* Empty Screen CSS */
#empty{
    padding:2rem;
    height: calc(100vh - 242px);
    display: flex;
    align-items: center;
}
.empty_image img{
    max-width: 30%;
}
.slash_clr .slash{
    background: #95AAC9;
}
.slash_clr{
    font-size: 0.625rem;
    margin-bottom: 0rem;
    letter-spacing: -0.02em;
}
.table h6{
    margin-bottom:0rem;
}

.input_tracking {
    border-bottom: 1px solid var(--clr-1) !important;
    border-radius: 0px;
    height: unset !important;
    padding: 0px 10px !important;
}

.form_label label {
    white-space: nowrap;
    line-height: 15px;
}
.pr_small {
    padding-right: 0px !important;
}
.dropdown-menu-right{
    top: 12px!important;
}
.tracking_modal_lg{
    max-width:1024px;
}
.td_wid_tracking{
    width: 53.2%;
}
.td_wid_tracking_69{
    width: 66%;
}
.wp_tracking .table tbody td {
    vertical-align: top;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 0px;
    border-bottom: 1px solid var(--clr-1);
    
}
.css-1s2u09g-control{
    border: 1px solid #D2DDEC!important;
}
.modal {
    overflow-y: auto !important;
  }
.wp_tracking .table tbody tr:last-child td {
    border: 0px;
}
.wp_tracking table{
    vertical-align: top;
    padding: 8px 12px !important;
    border: 1px solid var(--clr-1);
}

.wp_tracking .table-responsive > small {
    margin-bottom: 5px;
    display: block;
    font-weight: 500;
}
/* dublicate image */
.main_wrapper{
    padding:1rem 0;
}
.dublicate_image{
    margin-bottom:1.5rem;
}
.dublicate_image img{
    max-width: 45%;
}
.main_wrapper p{
    font-size: 1rem;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}


/* Dropzone */
.dropzone {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .dz-message {
    background-color: #fff;
    border: 1px dashed #d2ddec;
    border-radius: .375rem;
    color: #95aac9;
    cursor: pointer;
    order: -1;
    padding: 5rem 1rem;
    text-align: center;
    transition: all .2s ease-in-out;
    z-index: 999;
  }
  
  .dz-button {
    background: none;
    border: 0;
    color: inherit;
    font-size: inherit;
  }  
  .dz-preview-single {
    border-radius: .375rem;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .dz-preview-cover { 
    border-radius: .375rem;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .dz-preview-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius:.375rem;
  }
  
  .dz-preview-multiple .list-group-item:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
  
  [data-dz-size] strong {
    font-weight: 400;
  }

  .profile_tracking .avatar{
    width: 2.5rem;
    height: 2.5rem;
  }
  .new-select{
    display: flex;
    flex-wrap: wrap;
  }
  .fullwidth{
    width: 100%;
  }
  .select-gap{
    margin-right: 3px;
  }
.css-tlfecz-indicatorContainer {
 padding: 0 !important;
}

